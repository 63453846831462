import { createApp } from 'vue'
import App from './App.vue'

//全局导入element-plus 
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

//导入路由
import router from './router'

//全局导入element-plus/icons
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//导入公共样式
import './utils/common-layout.scss'

const app = createApp(App)
//使用ElementPlus
app.use(ElementPlus, {
    locale: zhCn,
})
//使用路由
app.use(router)
//注册element-plus/icons所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')