import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'

/**
 * 设置路由
 * path:路径
 * name:名称
 * component:地址
 * meta: { title: '' } :页面title标题
 */
const routes = [
  {
    path: '/',
    name: 'LoginView',  //登录页面
    component: () => import('../views/login/LoginView.vue'),
    meta: { title: '辰选英语' }
  },
  {
    path: '/home',
    name: 'HomeView',   //主页面
    component: () => import('../views/home/HomeView.vue'),
    meta: { title: '主页面' }
  }
]

const router = createRouter({
  //history模式:路由不带'#','/cxenglish/'是tomcat部署webapps下的文件夹名称
  // history: createWebHistory('/cxenglish/'),
  //不带项目名,直接ip或域名访问
  history: createWebHistory('/'),
  //hash模式:路由带'#'
  // history: createWebHashHistory(),
  routes: routes
})

//动态设置页面title
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

/**
 * Vue3如何配置Router路由:
 * https://baijiahao.baidu.com/s?id=1695710137398635356&wfr=spider&for=pc
 */
export default router;